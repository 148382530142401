<template>
	<div class="page-wrapper">
		<!-- WRAPPER -->
		<div class="list-scroll">
			<img v-if="logoUrl" :src="logoUrl" class="repository-logo" onerror="this.style.display = 'none'" />

			<div v-if="isNoSale" class="placeholder">
				No sales are available for online viewing at this time.
			</div>

			<div v-else>
				<div>
					<label>Sale</label>
					<h4 v-if="sales.length === 1">{{ sales[0].name }}</h4>
					<select v-else class="select" @change="event => updateSearch({ saleId: event.target.value, label: '' })">
						<option v-for="sale in sales" :key="sale.id" :value="sale.id" :selected="sale.id === search.saleId">
							{{ sale.name }}
						</option>
					</select>
				</div>
				<div v-if="labelsForSelectedSale.length && search.saleId" style="padding-top: 16px;">
					<label>Label</label>
					<select class="select" @change="event => updateSearch({ label: event.target.value })">
						<option :selected="!search.label"></option>
						<option
							v-for="label in labelsForSelectedSale"
							:key="label.label"
							:value="label.label"
							:selected="label.label === search.label"
						>
							{{ label.label }}
						</option>
					</select>
				</div>
				<div style="padding: 16px 0; max-width: 200px;">
					<ast-input
						label="Search"
						:debounce-milliseconds="250"
						:value="search.lotNumber"
						:placeholder="translate('consignerStudy') + ' number'"
						@input="lotNumber => updateSearch({ lotNumber })"
					/>
				</div>

				<div class="table-wrapper">
					<!-- Results are loaded, no results -->
					<section v-show="!isLoadingResults && !consignerStudies.length" class="no-results">
						<em>
							No results found
						</em>
					</section>

					<!--Table list-->
					<data-table
						v-if="mq.medium"
						v-show="consignerStudies.length || isLoadingResults"
						:class="{ 'has-cards': !mq.medium }"
						:columns="columns"
						:rows="sortedResults"
						:sort="listSort"
						:is-loading="isLoadingResults"
						@sort-selected="updateSortOrder"
						@row-open="handleRowOpen"
					>
						<template #row="{ row }">
							<td>{{ row.lotNumber }}</td>
							<td>{{ row.consignerName }}</td>
							<td>{{ row.label }}</td>
							<td v-if="isChipIdColumn">{{ row.chipId }}</td>
							<td class="break-sm">{{ row.sire }}</td>
							<td class="break-sm">{{ row.dam }}</td>
							<td>{{ row.accessionNumber }}</td>
							<td class="break-md" style="font-size: 0.85em;">
								{{ row.studyDateTime | localizeDate({ forceUTC: false }) }}
							</td>
							<td>
								<span
									v-for="(quantity, attachmentType) in row.attachments"
									:key="attachmentType"
									class="attachment"
									:title="attachmentType"
								>
									<svg-icon :icon="attachmentType.includes('Video') ? 'file-movie-o' : 'file-pdf-o'" fixed />
									<abbr>{{ getAttachmentTypeAbbreviation(attachmentType) }}</abbr>
									<small v-if="quantity > 1">x{{ quantity }}</small>
								</span>
							</td>
						</template>
					</data-table>

					<!--Card list-->
					<div v-if="!mq.medium" class="panel-list-container">
						<ast-card
							v-for="(result, i) in sortedResults"
							:key="i"
							class="study-card"
							:show-header="false"
							@click.native="handleRowOpen(result)"
						>
							<h4>{{ translate('consignerStudy') }} {{ result.lotNumber }}</h4>
							<p v-if="result.label">({{ result.label }})</p>
							<div style="margin-top: 16px;">
								<ul class="unlist">
									<li>
										<label>{{ translate('consigner') }}:</label>
										<span>{{ result.consignerName || '-' }}</span>
									</li>
									<li>
										<label>Accession Number:</label>
										<span>{{ result.accessionNumber || '-' }}</span>
									</li>
									<li>
										<label>Sire:</label>
										<span>{{ result.sire || '-' }}</span>
									</li>
									<li>
										<label>Dam:</label>
										<span>{{ result.dam || '-' }}</span>
									</li>
									<li v-if="sales.find(sale => sale.isChipIdVisible)">
										<label>Chip Id:</label>
										<span>{{ result.chipId }}</span>
									</li>
								</ul>
							</div>
							<p style="margin: 16px 0;">
								<svg-icon icon="clock-o" fixed />
								{{ result.studyDateTime | localizeDate({ forceUTC: false }) }}
							</p>
							<div>
								<span
									v-for="(quantity, attachmentType) in result.attachments"
									:key="attachmentType"
									class="attachment"
									:title="attachmentType"
								>
									<svg-icon :icon="attachmentType.includes('Video') ? 'file-movie-o' : 'file-pdf-o'" fixed />
									<abbr>{{ getAttachmentTypeAbbreviation(attachmentType) }}</abbr>
									<small v-if="quantity > 1">x{{ quantity }}</small>
								</span>
							</div>
						</ast-card>
					</div>
				</div>
				<ast-pagination
					v-show="consignerStudies.length"
					:current-page="sort.currentPage"
					:results-per-page="sort.resultsPerPage"
					:results-length="sortedResults.length"
					:has-more-results="hasMoreResults"
					@set-results-per-page="value => updateSort({ resultsPerPage: value })"
					@set-current-page="value => updateSort({ currentPage: value })"
				/>
			</div>
		</div>

		<consigner-study-list-client-modal
			v-if="showClientModal"
			:user-clients="userClients"
			@submit="startViewing"
			@close="showClientModal = false"
		/>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { findAttachmentUrl } from '@utils/urlUtility'

import AstPagination from '@components/Pagination'
import AstInput from '@components/Input'
import DataTable from '@components/DataTable'
import AstCard from '@components/Card'
import ConsignerStudyListClientModal from '@components/view/ConsignerStudiesListClientModal'
import { openPdfDlg } from '../../dialogs/attachmentDlg.vue'
import { Annex } from '@services/api'

export default {
	name: 'ConsignerStudyList',
	components: {
		DataTable,
		AstCard,
		AstPagination,
		AstInput,
		ConsignerStudyListClientModal,
	},
	data() {
		return {
			isLoading: false,
			isViewingStarted: false,
			userClients: [],
			showClientModal: false,
			activeConsignerStudyId: '',
			// [{ id, clinicCode, description, endpointBaseUrl }]
			pdfQueue: [],
			isLoadingResults: false,
			// TODO: this doesn't need to be reactive
			columns: [
				{
					columnName: 'lotNumber',
					isSortable: true,
					name: this.translate('consignerStudy'),
					sortName: 'lotNumber',
				},
				{
					columnName: 'consignerName',
					isSortable: true,
					name: this.translate('consigner'),
					sortName: 'consignerName',
				},
				{
					columnName: 'label',
					isSortable: true,
					name: 'Label',
					sortName: 'label',
				},
				{
					columnName: 'chipId',
					isSortable: true,
					name: 'Chip Id',
					sortName: 'chipId',
				},
				{
					columnName: 'sire',
					isSortable: true,
					name: 'Sire',
					sortName: 'sire',
				},
				{
					columnName: 'dam',
					isSortable: true,
					name: 'Dam',
					sortName: 'dam',
				},
				{
					columnName: 'accessionNumber',
					isSortable: true,
					name: 'Accession Number',
					sortName: 'accessionNumber',
				},
				{
					columnName: 'studyDateTime',
					isSortable: true,
					name: 'Study Date',
					sortName: 'studyDateTime',
				},
				{
					columnName: 'attachments',
					isSortable: false,
					name: 'Attachments',
				},
			],
			consignerStudies: [],
		}
	},
	computed: {
		...mapState({
			search: state => state.repository.search,
			sort: state => state.repository.sort,
			sales: state => state.repository.sales,
			requireClientName: state => state.repository.requireClientName,
			clinicCode: state => state.auth.claims.activeClinicCode,
		}),
		logoUrl() {
			if (!this.clinicCode) return null
			const base = Annex.defaults.baseURL
			return `${base}/Logo.aspx?ClinicCode=${this.clinicCode}`
		},
		labelsForSelectedSale() {
			if (!this.sales.length || !this.search.saleId) return []
			return this.sales.find(sale => sale.id === this.search.saleId).labels
		},
		isNoSale() {
			return !this.isLoading && !this.isLoadingResults && !this.sales.length
		},
		listSort() {
			return {
				orderBy: this.sort.order.by,
				isAscending: this.sort.order.isAscending,
				isSorted: true,
			}
		},
		activePdfUrl() {
			if (!this.pdfQueue.length) return ''
			const { id: imageId, clinicCode, endpointBaseUrl } = this.pdfQueue[0]
			return findAttachmentUrl({ imageId, clinicCode, endpointBaseUrl })
		},
		sortedResults() {
			const prop = this.sort.order.by
			let sortedResults = this.consignerStudies.slice().sort((a, b) => {
				if (a[prop] < b[prop]) return -1
				if (a[prop] > b[prop]) return 1
				return 0
			})
			if (!this.sort.order.isAscending) sortedResults.reverse()
			const start = this.sort.currentPage * this.sort.resultsPerPage
			const end = start + Number(this.sort.resultsPerPage)
			return sortedResults.slice(start, end)
		},
		hasMoreResults() {
			return this.consignerStudies.length > (this.sort.currentPage + 1) * this.sort.resultsPerPage
		},
		isChipIdColumn() {
			return this.columns.some(col => col.columnName === 'chipId')
		},
	},
	watch: {
		search: {
			handler(search) {
				this.refreshResults()
			},
			deep: true,
		},
		activePdfUrl(value) {
			if (value) {
				openPdfDlg(value, true, false).then(r => {
					if (r && r.reviewed) {
						this.markPdfAsReviewed()
					} else {
						this.endViewing()
					}
				})
			}
		},
	},
	async mounted() {
		if (this.search.saleId || this.search.lotNumber) {
			this.refreshResults()
		}
		this.isLoading = true
		await this.loadRepositoryData()
		this.isLoading = false
	},
	beforeRouteLeave(to, from, next) {
		if (to.name !== 'consigner-studies-viewer') this.endRepositoryViewing()
		next()
	},
	methods: {
		...mapMutations({
			updateSort: 'updateConsignerStudySort',
			updateSearch: 'updateConsignerStudySearch',
		}),
		...mapActions(['startRepositoryViewing', 'endRepositoryViewing']),
		async loadRepositoryData() {
			try {
				// After the repository data is loaded, we need to remove the "ChipId" if it's not in use by an active sale.
				// Most AU/NZ/FR sales use ChipId, but most US sales do not.
				let isChipIdVisible = this.sales.find(sale => sale.isChipIdVisible)
				if (!isChipIdVisible) {
					const chipIdColumnIndex = this.columns.findIndex(col => col.columnName === 'chipId')
					this.columns.splice(chipIdColumnIndex, 1)
				}
			} finally {
			}
		},
		async refreshResults() {
			if (!this.search.saleId) return
			this.isLoadingResults = true
			let params = {
				saleId: this.search.saleId,
			}
			if (this.search.label) params.label = this.search.label
			if (this.search.lotNumber) params.lotNumber = this.search.lotNumber
			try {
				const response = await this.$api.repository.getConsignerStudies(params)
				if (response) {
					this.consignerStudies = response.data
					if (this.sort.currentPage !== 0) this.updateSort({ currentPage: 0 })
				}
			} finally {
				this.isLoadingResults = false
			}
		},
		updateSortOrder(evt) {
			const changes = {
				order: {
					by: evt.name,
					isAscending: evt.isAscending,
				},
			}
			this.updateSort(changes)
		},
		async handleRowOpen(row) {
			this.activeConsignerStudyId = row.id
			if (this.requireClientName) {
				this.isLoading = true
				await this.loadUserClients()
				this.isLoading = false
				this.showClientModal = true
			} else {
				this.startViewing()
			}
		},
		async loadUserClients() {
			try {
				const { data } = await this.$api.user.getClients()
				const userClients = data.data
				this.userClients = [...new Set(userClients.map(userClient => userClient.label.trim()))]
			} finally {
			}
		},
		async startViewing(clientName = '') {
			if (this.isLoading || this.isViewingStarted) return
			if (this.requireClientName && !clientName) return
			this.showClientModal = false
			if (!this.activeConsignerStudyId) return
			try {
				this.isLoading = true
				await this.loadInitialViewingData(clientName)
				this.isViewingStarted = true
				if (!this.pdfQueue.length) this.openViewer()
			} catch (err) {
				this.$notifications.addError('Could not start the viewing.  Please try again later.')
			} finally {
				this.isLoading = false
			}
		},
		async loadInitialViewingData(clientName) {
			const responses = await Promise.all([
				this.startRepositoryViewing({
					clientName,
					consignerStudyId: this.activeConsignerStudyId,
				}),
				this.$api.repository.getRequiredAttachments(this.activeConsignerStudyId),
			])
			this.pdfQueue = responses[1].data
		},
		markPdfAsReviewed() {
			this.pdfQueue.shift()
			if (!this.pdfQueue.length) this.openViewer()
		},
		openViewer() {
			this.isLoading = true
			this.$router.push({
				name: 'consigner-studies-viewer',
				params: {
					id: this.activeConsignerStudyId,
				},
			})
			this.isLoading = false
		},
		endViewing() {
			this.isViewingStarted = false
			this.pdfQueue = []
			this.endRepositoryViewing()
		},
		getAttachmentTypeAbbreviation(attachmentType) {
			const words = attachmentType.split(' ')
			return words.map(word => word.charAt(0)).join('')
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.repository-logo {
	float: right;
	max-width: 300px;
	object-fit: contain;
	object-position: 0 0;
	height: 100px;
	max-height: 100px;
	margin-bottom: 0.5em;
}

.study-card {
	&:nth-child(odd) {
		background: var(--table-row-odd-bg);
	}
	li span {
		font-weight: 300;
		padding-left: 8px;
	}
}

@media (max-width: $mqLarge) {
	.repository-logo {
		display: none;
	}
}
span.attachment {
	padding-right: 0.5rem;
	white-space: nowrap;
}
.attachment abbr {
	font-size: 0.75em;
	font-weight: 500;
}
</style>
