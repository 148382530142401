<template>
	<modal-dialog class="consigner-client-modal" v-bind="dialog" @select="selectClient" @close="$emit('close')">
		<form class="client-form" @submit.prevent="$emit('select')">
			<label>Please select or type the name of the client for whom you are viewing.</label>
			<v-select
				ref="clientNameInput"
				v-model="clientName"
				:options="userClients"
				:searchable="true"
				:taggable="true"
				:clearable="false"
			/>
		</form>
	</modal-dialog>
</template>

<script>
import ModalDialog from '@components/ModalDialog'

export default {
	components: {
		ModalDialog,
	},
	props: {
		userClients: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			clientName: '',
		}
	},
	computed: {
		dialog() {
			return {
				name: 'clientNameDialog',
				isOpen: true,
				title: 'Client Name',
				actions: [
					{
						type: 'button',
						name: 'Submit',
						isDisabled: !this.clientName.trim(),
						emit: 'select',
					},
				],
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.clientNameInput.$refs.vueSelect.$refs.search.focus()
		})
	},
	methods: {
		selectClient() {
			if (!this.clientName.trim()) return
			this.$emit('submit', this.clientName)
		},
	},
}
</script>

<style lang="scss">
.consigner-client-modal {
	.client-form .v-select {
		margin-top: 16px;
	}
}
</style>
